/// <reference types='@lib/uber/types' />

import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table, TableRow, TableCell, TableBody, RotateLoader, styled } from "@lib/components";
import { Quotation, QuotationCreateParams } from "@lib/uber/types/resources/Quotations";
import { MobxComponent } from "../../../../mobx/component";
import { withTheme } from "styled-components";
import _ from 'lodash';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { RestaurantUtils } from '@lib/common';

const GoogleMapKeys = [
	"route",
	"locality",
	"administrative_area_level_1",
	"postal_code",
	"country",
	"street_number"
]

export const TimerContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #F5F5F5;
	color: white;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #F5F5F5;
	& span {
		font-size: 14px;
		margin-left: 5px;
	}
	margin-bottom: 20px;
`

export const Timer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	& span {
		color: #000000;
		font-weight: 600;
		font-size: 18px;
		line-height: 16px;
		margin-left: 0px;

		&.danger {
			color: #EA4335;
		}
	}

	& p {
		color: #313131;
		font-size: 14px;
		line-height: 16px;

		&.danger {
			color: #EA4335;
		}
	}
`

export const TimerButton  = styled.button`
	background-color: #313131;
	padding: 8px 16px;
	border: none;
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
	color: white;

	&.active {
		background-color: #d9d9d9;
		color: #757575;
	}
`

export const EstimateSuccess= styled.div`
	display: flex;
	align-items: center;
	background: rgba(81, 163, 81, 0.1);
	border-radius: 4px 0px 0px 4px;
	gap: 10px;
	padding: 16px 8px;
	margin-bottom: 20px;
	& p {
		color: #51A351;
		font-weight: 600;
		font-size: 16px;
		line-height: 16px;
	}
`
interface Props extends WithTranslation { }
interface State {
	quote: Quotation | null;
	error: string;
	loading: boolean;
	delivery_fee: number;
	tips: number;
	minutes: number;
	seconds: number;
	expiration_time: number;
	allowance: number;
	is_new_estimate: boolean;
	phone_number: string;
}

@inject("store") @observer
class UberEstimationClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			quote: null,
			error: '',
			loading: false,
			delivery_fee: 0,
			tips: 0,
			minutes: 0,
			seconds: 0,
			expiration_time: 0,
			allowance: 5,
			is_new_estimate: false,
			phone_number: this.injected.store.checkout.s.phone
		}
		
	}
	interval: any = null
	async componentDidMount() {
		const { store } = this.injected;
		const orderConfig = store.order_config.s;
		if(orderConfig.uber_quotation_id === undefined || 
			orderConfig.uber_quotation_id === '') {
			this.requestEstimate()
		} else {
			this.initTimer()
		}
	}

	componentWillUnmount() {
		const { store } = this.injected;
		store.order_config.update({
			uber_quotation_id: ''
		});
		clearInterval(this.interval);
	}

	initTimer() {
	
		clearInterval(this.interval);
		const { store } = this.injected;
		const orderConfig = store.order_config.s;
		if(orderConfig.uber_quotation_id) {
			const expirationTime = new Date(orderConfig.uber_quote_expire!).getTime();
			this.setState({
				//1 minute allowance
				expiration_time: new Date(expirationTime - (this.state.allowance * 60 * 1000)).getTime(),
				loading: false,
				delivery_fee: orderConfig.uber_total_fee!,
				quote: {
					id: orderConfig.uber_quotation_id!,
					duration: orderConfig.uber_duration!
				} as Quotation
			}, () => {
				this.timer()
				
			});
		} else {
			this.setState({ error: 'Invalid quotation.', loading: false, quote: null });
		}
	}
	timer() {
		this.interval = setInterval(() => {
		const now = new Date().getTime();
		const distance = this.state.expiration_time - now;
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		
		if(distance < 0) {
			this.setState({
				minutes: 0,
				seconds: 0
			});
			clearInterval(this.interval);
			this.injected.store.order_config.update({
				uber_error: 'Delivery estimation expired',
				uber_quotation_id: ''
			});
		} else {
			this.setState({
				minutes,
				seconds
			});
		}
		}, 1000);
	}

	// extend the interface of addressObj
	// for now, it supports only google maps
	getStructuredAddress(addressObj: T.Schema.Restaurant.RestaurantMapDataGoogleMaps["components"] | any) {
		let str;
		if (addressObj && Object.keys(addressObj).length == GoogleMapKeys.length  &&  _.every(Object.keys(addressObj), (key) => GoogleMapKeys.includes(key) )) {
			str = JSON.stringify({
				street_address: [`${addressObj.street_number} ${addressObj.route}`],
				city: addressObj.locality,
				state: addressObj.administrative_area_level_1,
				zip_code: addressObj.postal_code,
				country: addressObj.country,
			})
		}
		return str;
	}

	async requestEstimate() {
		const { store } = this.injected;
		const restaurant = store.restaurant!;
		const location = restaurant.location;
		const billing = store.billing;
		const orderConfig = store.order_config.s;
		// let commission_percent = 0;
		// let commission_amount = 0;
		// if ((billing?.stripe_connect.restaurants || []).length > 0 && !billing?.stripe_connect.restaurants![0].use_global_reseller_delivery_fee) {
		// 	commission_percent = billing?.stripe_connect.restaurants![0].restaurant_delivery_fee_percentage || 0
		// 	commission_amount = billing?.stripe_connect.restaurants![0].restaurant_delivery_fee_amount || 0
		// } else if (!billing?.stripe_connect.use_global_cw_delivery_fee) {
		// 	commission_percent = billing?.reseller_delivery_fee_percentage || 0
		// 	commission_amount = billing?.reseller_delivery_fee_amount || 0
		// } else if (billing?.stripe_connect.use_global_cw_delivery_fee) {
		// 	commission_percent = billing?.reseller_delivery_fee_percentage || 0
		// 	commission_amount = billing?.reseller_delivery_fee_amount || 0
		// }

		this.setState({ loading: true, phone_number: store.checkout.s.phone, error: ''})
		//@ts-ignore
		this.setState({ tips: store.cart.s.tip })
    const countryCode = RestaurantUtils.settings.getCountryCodeFromLocation(restaurant) as CountryCode;
    let formatPickupPhoneNumber = countryCode ? parsePhoneNumberFromString(location.phone, countryCode)?.number :
      parsePhoneNumberFromString(location.phone, 'AU')?.number ;

		// let formatPickupPhoneNumber =  parsePhoneNumberFromString(location.phone, 'AU')?.number ||
		// parsePhoneNumberFromString(location.phone, 'NZ')?.number ;
		const pickup = location.map_data.components;
    if(pickup.country?.toLowerCase().includes('united states')) {
      formatPickupPhoneNumber =  parsePhoneNumberFromString(location.phone, 'US')?.number;
    }else if(pickup.country?.toLowerCase().includes('canada')) {
      formatPickupPhoneNumber =  parsePhoneNumberFromString(location.phone, 'CA')?.number;
    }

		let formatDropoffPhoneNumber: any  = store.checkout.s.phone_e164_format;
		if(!formatDropoffPhoneNumber) {
			formatDropoffPhoneNumber = parsePhoneNumberFromString(store.checkout.s.phone, 'AU')?.number ||
			parsePhoneNumberFromString(store.checkout.s.phone, 'NZ')?.number ;
      if(pickup.country?.toLowerCase().includes('united states')) {
        formatDropoffPhoneNumber =  parsePhoneNumberFromString(store.checkout.s.phone, 'US')?.number;
      }else if(pickup.country?.toLowerCase().includes('canada')) {
        formatDropoffPhoneNumber =  parsePhoneNumberFromString(store.checkout.s.phone, 'CA')?.number;
      }
		}
		let params = {
			dropoff_address: this.getStructuredAddress(orderConfig.address_components) ?? orderConfig.destination,
			pickup_address:  this.getStructuredAddress(pickup as any) ?? location.address,
			//make sure to format to e164 all phonenumbers before sending request
			pickup_phone_number: formatPickupPhoneNumber,
			dropoff_phone_number: formatDropoffPhoneNumber,
			
		} as QuotationCreateParams

		if (orderConfig.due === "now") {
			const res = await store.api.order_eta({
				service: orderConfig.service,
				due: orderConfig.due,
				time: orderConfig.time,
				date: orderConfig.date,
				driving_time: orderConfig.driving_time,
			});
			if (res.outcome === 0 && res.ready_in_timestamp) params.pickup_ready_dt = new Date(res.ready_in_timestamp).toISOString();
		}

		const timestamp = orderConfig.timestamp;
		if (orderConfig.due === "later" && timestamp && timestamp > 0) {
			params.pickup_ready_dt = (new Date(timestamp - 1200000)).toISOString(); // RFC 3339c
      params.pickup_deadline_dt = (new Date(timestamp)).toISOString(); // RFC 3339c
			params.dropoff_ready_dt = params.pickup_ready_dt; // RFC 3339c
			params.dropoff_deadline_dt = (new Date(timestamp)).toISOString(); // RFC 3339c
		}

		
		const response = await store.api.create_uber_quote({
			restaurantId: restaurant._id,
			params,
		})

		if (response.outcome === 1) {
			this.setState({ error: response.message && response.message.toLowerCase().includes('invalid') ? 
			'Invalid phone number, please try again.': response.message, loading: false, quote: null });
			store.order_config.update({
				uber_error: response.message,
				uber_quotation_id: ''
			})
		} else if (response.quote) {
			const expirationTime = new Date(response.quote.expires).getTime();
			this.setState({
				//5 minute allowance
				expiration_time: new Date(expirationTime - (this.state.allowance * 60 * 1000)).getTime()
			}, () => {
				this.timer()
			});
			//const delivery_fee = Number(commission_amount) + (response.quote.fee/100) + ((commission_percent/100) * (response.quote.fee/100))
			store.order_config.update({
				delivery_provider: "uber",
				uber_quotation_id: response.quote.id,
				uber_quote_amount: response.quote.fee/100,
				uber_quote_payload: params,
				uber_total_fee: response.quote.computed_delivery_fee,
				uber_quote_expire: response.quote.expires,
				uber_duration: response.quote.duration,
				uber_error: '',
				uber_delivery_error: ''
			});
			this.setState({ 
				quote: response.quote,
				loading: false,
				delivery_fee: response.quote.computed_delivery_fee,
				error: ''
			});
			
			if(this.state.is_new_estimate) {
				setTimeout(() => this.setState({
					is_new_estimate: false
				}) , 1500);
			}
		}
	}

	render() {
		const { t, theme, store } = this.injected;
		const quote = this.state.quote;
		const error = this.state.error;
		const delivery_fee = this.state.delivery_fee;
		const tips = this.state.tips;
		const {minutes, seconds} = this.state;
		const isLessThanTenSeconds = minutes == 0 && seconds < 10;
		if(store.checkout.s.phone !== this.state.phone_number) {
			this.requestEstimate();
		}
		return (
			<>
				{this.state.loading && <RotateLoader size={3} color={theme.colors.primary} />}

				{quote  && (
					<> 
					{!this.state.loading  && this.state.is_new_estimate && quote &&
					<EstimateSuccess>

						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.59 5.58L8 12.17L4.41 8.59L3 10L8 15L16 7L14.59 5.58ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="#51A351"/>
						</svg>
						<p>{ t("store.modals.checkout.delivery_estimation.details.uber_new_estimation_success") }</p>

					</EstimateSuccess>}
					{ (!this.state.loading && !this.state.is_new_estimate && 
					<TimerContainer>
						<Timer>
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9998 24.6666C19.8665 24.6666 24.6665 19.8666 24.6665 14C24.6665 8.13329 19.8665 3.33329 13.9998 3.33329C8.13317 3.33329 3.33317 8.13329 3.33317 14C3.33317 19.8666 8.13317 24.6666 13.9998 24.6666ZM13.9998 0.666626C21.3332 0.666626 27.3332 6.66663 27.3332 14C27.3332 21.3333 21.3332 27.3333 13.9998 27.3333C6.6665 27.3333 0.666504 21.3333 0.666504 14C0.666504 6.66663 6.6665 0.666626 13.9998 0.666626ZM20.6665 13.3333V15.3333H12.6665V7.33329H14.6665V13.3333H20.6665Z"
								fill={isLessThanTenSeconds ? '#EA4335': 'black'}/>
							</svg>
							<div>
								<span className={isLessThanTenSeconds ? 'danger': ''}>00:{_.padStart(minutes.toString(), 2, '0')}:{_.padStart(seconds.toString(), 2, '0')}</span>
								<p className={isLessThanTenSeconds ? 'danger': ''}>
									{minutes == 0 && seconds == 0 ? t("store.modals.checkout.delivery_estimation.details.uber_estimation_expired"):
									 t("store.modals.checkout.delivery_estimation.details.uber_price")}
								</p>
							</div>

						</Timer>
						{minutes > 0 || seconds > 0 ?
						<TimerButton onClick={(e) => {
							e.preventDefault();
							if(this.state.allowance > 0) {
							this.setState({
								allowance: 0
							}, () => {
								this.initTimer()
							});
							}
						}} className={this.state.allowance === 0 ? 'active': ''}>
							{ t("store.modals.checkout.delivery_estimation.details.uber_need_time") }
						</TimerButton> :
						<TimerButton onClick={(e) =>  {
							e.preventDefault();
							this.setState({
								is_new_estimate: true
							});
							this.requestEstimate();
						}}>
							{ t("store.modals.checkout.delivery_estimation.details.uber_new_estimation") }
						</TimerButton>}
					</TimerContainer> )}
					<Table>
						<TableBody>
							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.provider")}</TableCell>
								<TableCell className="small">Uber</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_fee")}</TableCell>
								<TableCell className="small">{t("currency", { value: delivery_fee })}</TableCell>
							</TableRow>
							
							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.tips")}</TableCell>
								<TableCell className="small">{t("currency", { value: tips })}</TableCell>
							</TableRow>

							<TableRow className="no-border">
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_delivery_time")}</TableCell>
								<TableCell className="small">{quote.duration} minutes</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					</>
				)}

				{error && <div className="error-text" style={{ fontSize: '0.85rem' }}>{error}</div>}
			</>
		);
	}
}

// @ts-ignore
export const UberEstimation = withTheme(withTranslation()(UberEstimationClass));
