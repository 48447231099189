import * as React from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import readableColor from "polished/lib/color/readableColor";
import { withTranslation, WithTranslation } from "react-i18next";

import { config } from "../../../../../config";
import { MapboxAPI } from "../../../../libs/mapbox";
import { MobxComponent } from "../../../../mobx/component";
import { GoogleMapPickerModal } from "./marker_modal.google";
import { LocationConfirmModal } from "./location_confirm_modal";
import { OrderUtils, logger, GoogleServiceLib, RestaurantUtils } from "@lib/common";
import { MapboxGeocodeAutocompleteResultFeature } from "@lib/common";
import { SubscriptionContext } from "../../../shared/contexts/subscription";
import { ModalContent, SearchModal, InputBox, RotateLoader, Input, withTheme, Button } from "@lib/components";

export type Option = (MapboxGeocodeAutocompleteResultFeature | google.maps.places.AutocompletePrediction | google.maps.GeocoderResult) & {
  label: string;
};

interface Props extends WithTranslation { }

interface State {
  locationModalActive: boolean;
  locationConfirmModalActive: boolean;
  showLocationPermissionWarning: boolean;
}

const LocationButton = styled(Button)`
	margin: 0 5px;
	padding-left: 5px;
	padding-right: 5px;
	font-weight: normal;
  color: rgba(50,50,50,1);
	border: 1px solid #ccc;
`;

const LocationButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
`;

const LocationPermissionWarning = styled.div`
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
	position: relative;
	padding: .75rem 1.25rem;
	border: 1px solid transparent;
	border-radius: .25rem;
	margin-top: 1rem;
	font-size: 14px;
	line-height: 1.2;
`;

@inject("store") @observer
class OrderConfigAddressGoogleClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locationModalActive: false,
      locationConfirmModalActive: false,
      showLocationPermissionWarning: false,
    }
  }

  handleAddressSearchGM = async (search: string) => {
    if (!search || search.length < 5) return [];
    const { store } = this.injected;
    let restrictCountry = store.restaurant.settings.services.delivery.restrict_delivery_addresses;
    if (!restrictCountry) {
      const storeCountryCode = RestaurantUtils.settings.getCountryCodeFromLocation(store.restaurant);
      restrictCountry = storeCountryCode ? storeCountryCode : "";
    }

    const restrictSpecific = store.restaurant.settings.services.delivery.force_specific_address;
    const places = await store.googleMapsClient!.autocomplete_place({
      input: search,
      componentRestrictions: {
        country: restrictCountry ? [restrictCountry] : [],
      },
      types: restrictSpecific || 
        store.restaurant.settings.services.delivery.default_delivery_provider === 'uber' ? ["address"] : 
        [],
    });
    return places.map((p) => ({
      ...p,
      label: p.description,
    }));
  }

  handleAddressSearchOSM = async (search: string) => {
    if (!search || search.length < 5) return [];
    const r = this.injected.store.restaurant;
    let restrictCountry = r.settings.services.delivery.restrict_delivery_addresses;
    if (!restrictCountry) {
      const storeCountryCode = RestaurantUtils.settings.getCountryCodeFromLocation(r);
      restrictCountry = storeCountryCode ? storeCountryCode : "";
    }
    const md = r.location.map_data;
    let proximity = "";
    if (md.type === "google_maps") {
      proximity = `${md.lng},${md.lat}`;
    }

    const result = await MapboxAPI.geocode_autocomplete(search, {
      country: restrictCountry || "",
      language: this.injected.store.intl.s.lng,
      proximity: proximity,
      types: "address,poi",
    });

    return result.features.map((p) => ({
      ...p,
      label: p.place_name,
    }));
  }

  handleAddressSelect = async (selected: Option) => {
    const { t, store } = this.injected;
    const r = this.injected.store.restaurant;
    const oc = this.injected.store.order_config;
    const zones = this.injected.store.serviceDeliveryZones;
    const isOSM = store.mapType === "osm";
    let address_components = {};

    const commonUpdates = {
      success: {
        delivery_error: "",
        delivery_loading: false,
      },
      selected: {
        delivery_loading: true,
        delivery_modal: false,
        delivery_error: "",
      },
      blocked: {
        delivery_modal: false,
        delivery_loading: false,
        delivery_error: t("store.modals.order.config.address.not_delivering"),
      },
      invalid: {
        delivery_loading: false,
        delivery_error: zones.length > 0 ? t("store.modals.order.config.address.not_in_zone") : t("store.modals.order.config.address.not_in_range"),
      },
      not_serviceable: {
        delivery_loading: false,
        delivery_error: t("store.modals.order.config.address.not_in_zone")
      },
      not_found: {
        delivery_loading: false,
        delivery_error: t("store.modals.order.config.address.not_found"),
      },
      no_number: {
        delivery_loading: false,
        delivery_error: t("store.modals.order.config.address.no_number"),
      },
    };

    try {
      let address = "";
      let lat = 0;
      let lng = 0;
      let zip_code = "";

      oc.updateD(commonUpdates.selected);

      if (isOSM) {
        selected = selected as MapboxGeocodeAutocompleteResultFeature & { label: string };
        address = selected.place_name;
        lng = selected.center[0];
        lat = selected.center[1];
      } else {
        selected = selected as (google.maps.places.AutocompletePrediction | google.maps.GeocoderResult) & { label: string };
        const place = await store.googleMapsClient!.place_get(selected.place_id);
        if (!place.geometry || !place.formatted_address) {
          oc.setService("delivery");
          oc.updateD({
            delivery_address: place.formatted_address || ("description" in selected ? selected.description : ""),
            ...commonUpdates.not_found,
          });
          return;
        }

        if (place.formatted_address.indexOf(place.name) === -1) {
          address = `${place.name} - ${place.formatted_address}`;
        } else {
          address = place.formatted_address;
        }

        const componentForm: T.Schema.Restaurant.RestaurantMapDataGoogleMaps["components"] = {
					street_number: "short_name",
					route: "long_name",
					locality: "long_name",
					administrative_area_level_1: "short_name",
					country: "long_name",
					postal_code: "short_name",
				};

				const components: Partial<T.Schema.Restaurant.RestaurantMapDataGoogleMaps["components"]> = {};
				//@ts-ignore
        for (const component of place.address_components) {
					const t = component.types[0] as keyof T.Schema.Restaurant.RestaurantMapDataGoogleMaps["components"];
					if (componentForm[t]) {
            // get the zip code for later use
            if (t === "postal_code") {
              zip_code = component.long_name
            }
						components[t] = component[componentForm[t] as "long_name" | "short_name"];
					}
				}
        address_components = components;
        lng = place.geometry.location.lng();
        lat = place.geometry.location.lat();

        const restrictNumber = store.restaurant.settings.services.delivery.force_address;
        if (restrictNumber && place.address_components) {
          const streetNumberElements = place.address_components.filter(comp => comp.types.includes("street_number"))
          if (streetNumberElements.length === 0) {
            oc.setService("delivery");
            oc.updateD({
              delivery_address: address,
              ...commonUpdates.no_number,
            });
            return;
          }
        }
      }

      const block_addresses = r.settings.services.delivery.block_addresses;
      const blocked = OrderUtils.checkBlockedAddresses(address, block_addresses);
      if (blocked) {
        oc.setService("delivery");
        oc.updateD({
          delivery_address: address,
          ...commonUpdates.blocked,
        });
        return;
      }
      const isServiceable = oc.is_area_serviceable({ address, zip_code })

      if (!isServiceable) {
        oc.setService("delivery");
        oc.updateD({
          delivery_address: address,
          ...commonUpdates.not_serviceable,
        });
        return;
      }

      const { valid, distance, driving_time, zoneMatch } = await oc.validate_delivery_details({ lat, lng });

      if (!valid) {
        oc.setService("delivery");
        oc.updateD({
          delivery_address: address,
          ...commonUpdates.invalid,
        });
      } else {
        logger.info("SET DESTINATION", selected, address);
        oc.setDestination({
          destination: address,
          destination_misc: "",
          lat: lat,
          lng: lng,
          distance: distance,
          driving_time: driving_time,
          zone: zoneMatch ? zoneMatch.name : "",
          address_components: isOSM ? selected : address_components
        });
        oc.updateD({
          delivery_address: address,
          ...commonUpdates.success,
        });
      }
    } catch (e) {
      logger.captureException(e);
      oc.updateD({
        delivery_address: selected.label,
        ...commonUpdates.not_found,
      });
    }
  }

  handleLocationPermission = () => {
    if (!("geolocation" in navigator)) {
      return;
    }

    const options: PositionOptions = {
      maximumAge: 0,
      timeout: 5000,
      enableHighAccuracy: true,
    };

    const onSuccess = async (position: any) => {
      const googleService = GoogleServiceLib(config.google_api_key)
      const crd = position.coords;
      const lat = crd.latitude;
      const lng = crd.longitude;

      const geocodingResult = await googleService.geocode({ location: { lat, lng } });
      if (geocodingResult && geocodingResult.length > 0) {
        this.handleAddressSelect({ ...geocodingResult[0], label: geocodingResult[0].formatted_address });
      }
    }

    const onError = (err: any) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      this.setState({ showLocationPermissionWarning: true })
    }

    this.setState({ showLocationPermissionWarning: false });
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  }

  render() {
    const { t, store, theme } = this.injected;
    const oc = this.injected.store.order_config;
    const { delivery_address, delivery_error, delivery_loading, delivery_modal } = oc.d;
    const buttonText = oc.s.destination || delivery_address || t("store.modals.order.config.address.input_placeholder");
    const googleLogo = readableColor(theme.box.background) === "#000" ?
      "/attributions/google_powered_by_black.png" :
      "/attributions/google_powered_by.png";

    const { locationModalActive, locationConfirmModalActive, showLocationPermissionWarning } = this.state;
    const pickerDefaultLocation = { lat: oc.s.lat, lng: oc.s.lng, address: oc.s.destination };
    const destinationAvailable = oc.s.destination && oc.s.lat && oc.s.lng;
    const destinationMisc = oc.s.destination_misc;
    const usingGoogleMaps = store.mapType === "google_maps";
    const useMapPicker = store.restaurant.settings.services.delivery.map_picker_enabled === true;

    return (
      <SubscriptionContext.Consumer>
        {({ isFreePlan }) => (
          <div className="m-t-6">
            <div className="text-center">
              <InputBox
                id="address-input-box"
                type="button"
                autoHeight={true}
                onClick={() => oc.updateD({ delivery_modal: true })}
                style={{ paddingTop: "8px", paddingBottom: "8px", color: "black" }}
                className="p-tb-2 p-lr-4 cursor width100 max450 center">
                {delivery_loading ? <RotateLoader size={2} /> : <p className="small lhp">{buttonText}</p>}
              </InputBox>

              {!isFreePlan && usingGoogleMaps && useMapPicker && (
                <LocationButtonWrapper>
                  <LocationButton
                    size="xxs"
                    color="white"
                    onClick={() => this.setState({ locationConfirmModalActive: true })}>
                    {t("store.modals.order.config.address.use_current_location")}
                  </LocationButton>

                  {destinationAvailable && (
                    <LocationButton
                      size="xxs"
                      color="white"
                      onClick={() => this.setState({ locationModalActive: true })}>
                      {t("store.modals.order.config.address.locate_on_map")}
                    </LocationButton>
                  )}
                </LocationButtonWrapper>
              )}
            </div>

            {oc.s.destination && (
              <div className="text-center m-t-3">
                <Input
                  style={{ fontSize: "14px" }}
                  type="text"
                  value={destinationMisc}
                  onChange={(e) => oc.update({ destination_misc: e.target.value })}
                  placeholder={t("store.modals.order.config.address.extras_placeholder")}
                  className="width100 max450 text-center" />
              </div>
            )}

            {delivery_error && (
              <p className="m-t-4 text-center error-text lhp small">{delivery_error}</p>
            )}

            {showLocationPermissionWarning && (
              <LocationPermissionWarning>
                {t("store.modals.order.config.address.location_permission_warning")}
                <ul>
                  <li><a href="https://support.google.com/chrome/answer/142065" target="_blank">Google Chrome</a></li>
                  <li><a href="https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites" target="_blank">Firefox</a></li>
                </ul>
              </LocationPermissionWarning>
            )}

            <SearchModal<Option>
              level={2}
              width="sm"
              innerId="address-search-modal"
              translationFunction={t}
              active={delivery_modal}
              close={() => oc.updateD({ delivery_modal: false })}
              handleSelect={this.handleAddressSelect}
              handleSearch={store.mapType === "osm" ? this.handleAddressSearchOSM : this.handleAddressSearchGM}
              attribution={store.mapType === "google_maps" ? (
                <ModalContent paddingtb={12} className="text-center">
                  <img src={googleLogo} alt="google-attribution" />
                </ModalContent>
              ) : null} />

            {!isFreePlan && usingGoogleMaps && useMapPicker && (
              <GoogleMapPickerModal
                active={locationModalActive}
                handleLocationPermission={this.handleLocationPermission}
                onClose={() => this.setState({ locationModalActive: false })}
                handleAddressSelect={this.handleAddressSelect}
                pickerDefaultLocation={pickerDefaultLocation}
              />
            )}

            {!isFreePlan && usingGoogleMaps && useMapPicker && (
              <LocationConfirmModal
                active={locationConfirmModalActive}
                onClose={() => this.setState({ locationConfirmModalActive: false })}
                onProceed={() => this.handleLocationPermission()}
              />
            )}
          </div>
        )}
      </SubscriptionContext.Consumer>
    );
  }
}

// @ts-ignore
export const OrderConfigAddressGoogle = withTheme(withTranslation()(OrderConfigAddressGoogleClass));
